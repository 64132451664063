<script>
import ChallengeSubmissionListView from "./ChallengeSubmissionListView.vue";
import { GetSubmissionsAuthenticated, GetSubmissionsUnauthenticated } from "../challengePromptSubmissionOperations.gql";

export default {
  name: "ChallengeSubmissionListPipe",
  components: {
    ChallengeSubmissionListView
  },
  props: {
    promptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    submissions() {
      if (!this.resultPrompt) return [];
      return this.resultPrompt.prompt_users.map(prompt_user => {
        const post = prompt_user.post;
        // Calculate total reply count
        const replyCount = post.posts_threads.reduce((total, thread) => {
          return total + (thread.threads_posts_aggregate?.aggregate?.count || 0);
        }, 0);
        return {
          ...post,
          submitted_at: prompt_user.submitted_at,
          replyCount
        };
      });
    }
  },
  apollo: {
    resultPrompt: {
      query() {
        return this.$auth.isAuthenticated ? GetSubmissionsAuthenticated : GetSubmissionsUnauthenticated;
      },
      variables() {
        const vars = {
          promptId: this.promptId
        };
        if (this.$auth.isAuthenticated) {
          vars.userId = this.$auth.user.id;
        }
        return vars;
      },
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <ChallengeSubmissionListView :submissions="submissions" :is-loading="isLoading" />
</template>
