import { AcceptChallenge, UnacceptChallenge } from "./challengeOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
import { call } from "@/utils/api/base.js";

export async function acceptChallenge(challengeId, userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Challenge not specified."],
    [BE.NOT_EXIST, "Challenge does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Failed to accept challenge. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AcceptChallenge,
        variables: {
          challengeId,
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unacceptChallenge(challengeId, userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Challenge not specified."],
    [BE.NOT_EXIST, "Challenge does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Failed to unaccept challenge. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnacceptChallenge,
        variables: {
          challengeId,
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
