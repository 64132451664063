<script>
import { SuawCtaCard } from "@/components";
import * as ChallengeApi from "../../challenge/challengeApi";

export default {
  name: "AcceptChallengeCtaCard",
  components: { SuawCtaCard },
  props: {
    challengeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    showAcceptModal() {
      this.showModal = true;
    },
    async acceptChallenge() {
      const result = await ChallengeApi.acceptChallenge(this.challengeId, this.$auth.user.id);
      this.handleActionResult(result);
    },
    handleActionResult(result) {
      this.showModal = false;
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      this.$emit("challenge-accepted");
    },
    onCancelModal() {
      this.showModal = false;
    }
  }
};
</script>

<template>
  <div>
    <SuawModal
      v-if="showModal"
      modal-title="Are you sure you want to accept this challenge? You will receive an email reminder when each prompt is unlocked. You must submit prompt submissions before the challenge is over."
      modal-type="confirmation"
      :toggle-button-confirm="acceptChallenge"
      :toggle-button-cancel="onCancelModal"
    />
    <SuawCtaCard
      heading="Accept Challenge to Create a Submission."
      text="By accepting this challenge you will receive email reminders when each prompt is unlocked and will have the ability to create submissions until the challenge ends."
      primary-button-text="ACCEPT CHALLENGE"
      @click="showAcceptModal"
    />
  </div>
</template>
