<script>
import UnlockedChallengePromptView from "./UnlockedChallengePromptView.vue";
import LockedChallengePromptView from "./LockedChallengePromptView.vue";
import { GetPromptAuthenticated, GetPromptUnauthenticated } from "../challengeOperations.gql";
import { SuawEmptyState } from "@/components";

export default {
  name: "ChallengePromptPipe",
  components: {
    UnlockedChallengePromptView,
    LockedChallengePromptView,
    SuawEmptyState
  },
  props: {
    promptId: {
      type: String,
      required: true
    },
    unlockedIsClickable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    }
  },
  apollo: {
    resultPrompt: {
      query() {
        return this.$auth.isAuthenticated ? GetPromptAuthenticated : GetPromptUnauthenticated;
      },
      variables() {
        const vars = {
          id: this.promptId
        };
        if (this.$auth.isAuthenticated) {
          vars.userId = this.$auth.user.id;
        }
        return vars;
      },
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <div v-if="!isLoading">
    <UnlockedChallengePromptView v-if="!resultPrompt.is_locked" :is-clickable="unlockedIsClickable" :prompt="resultPrompt" />
    <LockedChallengePromptView v-else :prompt="resultPrompt" />
  </div>
  <SuawEmptyState v-else message="Loading prompt..." />
</template>
