<script>
import ChallengeSubmissionDisplayView from "./ChallengeSubmissionDisplayView.vue";
import { SuawInputGroup, SuawFilterBar, SuawEmptyState, SuawHeading } from "@/components";
export default {
  name: "ChallengeSubmissionListView",
  components: {
    ChallengeSubmissionDisplayView,
    SuawInputGroup,
    SuawFilterBar,
    SuawEmptyState,
    SuawHeading
  },
  props: {
    submissions: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortMode: "date", // "date" or "likes"
      sortDirection: "desc", // "asc" or "desc",
      rightFilterList: [
        {
          label: "Sort by:",
          dropdownItems: [
            { text: "Newest First", filter: "Sort", value: { mode: "date", direction: "desc" } },
            { text: "Oldest First", filter: "Sort", value: { mode: "date", direction: "asc" } },
            { text: "Most Liked", filter: "Sort", value: { mode: "likes", direction: "desc" } },
            { text: "Least Liked", filter: "Sort", value: { mode: "likes", direction: "asc" } }
          ]
        }
      ]
    };
  },
  computed: {
    showEmptyState() {
      return this.isLoading || this.sortedSubmissions.length === 0;
    },
    sortedSubmissions() {
      if (!this.submissions) return [];
      const sorted = [...this.submissions].sort((a, b) => {
        if (this.sortMode === "date") {
          const aDate = new Date(a.posted_at);
          const bDate = new Date(b.posted_at);
          return this.sortDirection === "asc" ? aDate - bDate : bDate - aDate;
        } else {
          const aLikes = a.post_reactions_aggregate?.aggregate?.count || 0;
          const bLikes = b.post_reactions_aggregate?.aggregate?.count || 0;
          return this.sortDirection === "asc" ? aLikes - bLikes : bLikes - aLikes;
        }
      });
      return sorted;
    }
  },
  methods: {
    onFilterChange({ text, filter, value }) {
      if (filter === "Date") {
        this.selectedDate = text;
      } else if (filter === "Sort" && value) {
        this.sortMode = value.mode;
        this.sortDirection = value.direction;
      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="showEmptyState">
      <SuawInputGroup direction="column" group-gap="triple">
        <SuawHeading level="3" content="Submissions" />
        <SuawEmptyState :message="isLoading ? 'Loading Submissions' : 'There are no submissions for this prompt.'" />
      </SuawInputGroup>
    </template>
    <SuawInputGroup v-else group-gap="triple" direction="column">
      <SuawFilterBar :right-filter-list="rightFilterList" heading-text="Submissions" button-size="medium" @item-click="onFilterChange" />
      <ChallengeSubmissionDisplayView v-for="submission in sortedSubmissions" :key="submission.id" :submission="submission" />
    </SuawInputGroup>
  </div>
</template>
