<script>
import ChallengeSubmissionInputView from "./ChallengeSubmissionInputView.vue";
import * as ChallengePromptApi from "../challengePromptSubmssionApi";
import { format } from "date-fns";

export default {
  name: "ChallengeSubmissionInputPipe",
  components: {
    ChallengeSubmissionInputView
  },
  props: {
    promptId: {
      type: String,
      required: true
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    isSubmitted: {
      type: Boolean
    },
    initialBodyJson: {
      type: Object,
      default: null
    },
    lastSavedAt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      autoSaveTime: null
    };
  },
  computed: {
    updatedLastSaveTime() {
      return this.autoSaveTime ? format(this.autoSaveTime, "MMM d, yyyy h:mm a") : this.lastSavedAt;
    }
  },
  methods: {
    async handleDraft(content) {
      if (!this.isEditing) {
        const result = await ChallengePromptApi.draftPromptResponse(this.promptId, content);
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        } else {
          this.autoSaveTime = new Date();
        }
      }
    },
    async handleSubmit(content) {
      if (!this.isEditing) {
        // First draft for new submissions
        const draftResult = await ChallengePromptApi.draftPromptResponse(this.promptId, content);
        if (!draftResult.success) {
          this.$root.$emit("universal-error-message", draftResult.error);
          return;
        }
      }

      // Submit for both new and edited submissions
      const submitResult = await ChallengePromptApi.submitPromptResponse(this.promptId, content);
      if (!submitResult.success) {
        this.$root.$emit("universal-error-message", submitResult.error);
        return;
      }

      this.$emit("submit-success");
    }
  }
};
</script>

<template>
  <ChallengeSubmissionInputView
    :initial-body-json="initialBodyJson"
    :is-submitted="isSubmitted"
    :last-saved-at="updatedLastSaveTime"
    @draft="handleDraft"
    @submit="handleSubmit"
    @cancel-edit="$emit('cancel-edit')"
  />
</template>
