<script>
import { SuawParagraph, SuawHeading, SuawDivider, SuawIcon } from "@/components";
import { format } from "date-fns";

export default {
  name: "LockedChallengePromptView",
  components: {
    SuawParagraph,
    SuawHeading,
    SuawDivider,
    SuawIcon
  },
  props: {
    prompt: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedDate() {
      return format(new Date(this.prompt.unlocks_at), "MMM d, yyyy");
    }
  }
};
</script>

<template>
  <div class="locked-challenge-prompt">
    <div class="locked-challenge-prompt__title-row">
      <SuawHeading class="locked-challenge-prompt__title" level="3" :content="prompt.title" />
      <SuawIcon class="locked-challenge-prompt__icon" icon="IconLock" color="dark-gray" size="large" />
    </div>
    <SuawDivider />
    <div class="locked-challenge-prompt__footer">
      <div class="locked-challenge-prompt__footer-section">
        <SuawParagraph weight="bolder" size="small" text="Unlocks:" />
        <SuawParagraph class="locked-challenge-prompt__release-date" weight="bold" size="small" :text="formattedDate" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.locked-challenge-prompt {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: inherit;
  background: var(--sem-color-background-regular);
  border-radius: 8px;
  padding: 20px;
  &__title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  &__icon {
    align-self: flex-start;
  }

  &__title {
    font-weight: 700;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer-section {
    display: flex;
    justify-content: center;
    gap: 4px;
  }

  &__release-date {
    color: var(--sem-color-neutral-medium);
  }
}
</style>
