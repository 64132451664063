<script>
import { SuawHeading, SuawButton, SuawParagraph, SuawDivider } from "@/components";
export default {
  name: "UnfinishedSubmissionDraftCtaCard",
  components: { SuawHeading, SuawButton, SuawParagraph, SuawDivider },
  props: {
    draft: {
      type: Object,
      default: null
    },
    lastSavedAt: {
      type: String,
      default: ""
    }
  }
}
</script>

<template>
  <div class="unfinished-submission-card">
    <div class="unfinished-submission-card__content">
      <div class="unfinished-submission-card__heading-container">
        <SuawHeading class="unfinished-submission-card__heading" level="3" alignment="center" content="The Challenge has ended." weight="bold" />
      </div>
      <div class="unfinished-submission-card__text-container">
        <SuawParagraph
          class="unfinished-submission-card__text"
          alignment="center"
          text="This challenge ended before you could submit your draft. Check out the next challenge and click Accept to get email reminders of when prompts unlock. You can also read through past challenges and comment on other members' work. Here is the latest version of your draft for your records."
          siz="medium"
          weight="bold"
        />
      </div>
      <SuawDivider />
      <SuawParagraph
      class="unfinished-submission-card__draft-text"
      :text="draft"
      />
      <SuawDivider />
      <div class="unfinished-submission-card__footer">
        <div v-if="lastSavedAt" class="unfinished-submission-card__date-group">
          <SuawParagraph weight="bolder" size="small" text="Last edited at:" />
          <SuawParagraph class="unfinished-submission-card__date" weight="bold" size="small" :text="lastSavedAt" />
        </div>
        <div class="unfinished-submission-card__button-container">
          <SuawButton
            class="unfinished-submission-card__button"
            buttonText="Go Back to Challenges"
            size="large"
            type="primary"
            @click="$router.push( {name: 'ChallengeDiscovery' } )"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.unfinished-submission-card {
  // background-color: var(--sem-color-background-lightest);
  // box-shadow: var(--soft-shadow-draft);
  // border-radius: var(--sem-radius-md);
  // padding: var(--sem-space-triple);
  width: 100%;
  max-width: 1264px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    // max-width: 720px;
    background-color: var(--sem-color-background-lightest);
    box-shadow: var(--soft-shadow-draft);
    border-radius: var(--sem-radius-md);
    padding: var(--sem-space-triple);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-triple);
    justify-content: center;
    align-items: center;
  }
  &__footer {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @media(max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }
  &__button-container {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .online-button {
    background: #6737ff;
  }
  &__heading {
    font-weight: 500;
  }
  &__text-container {
    max-width: 720px;
  }
  &__text {
    color: var(--sem-color-text-darken);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
  &__draft-text {
    // background-color: var(--sem-color-background-light);
    // box-shadow: var(--soft-shadow-draft);
    // border-radius: var(--sem-radius-md);
    // padding: var(--sem-space-triple);
  }
  &__date-group {
    display: flex;
    align-items: center;
    gap: var(--sem-space-half);
  }
  &__date {
    color: var(--sem-color-neutral-medium);
  }
}
</style>
