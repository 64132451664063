<script>
import ChallengeSubmissionDisplayView from "./ChallengeSubmissionDisplayView.vue";
import ChallengeSubmissionInputPipe from "./ChallengeSubmissionInputPipe.vue";
import UnfinishedSubmissionDraftCtaCard from "./UnfinishedSubmissionDraftCtaCard.vue";
import AcceptChallengeCtaCard from "./AcceptChallengeCtaCard.vue";
import { SuawCtaCard, SuawHeading, SuawInputGroup, SuawEmptyState } from "@/components";
import { format } from "date-fns";

export default {
  name: "ChallengeSubmissionYoursView",
  components: {
    ChallengeSubmissionDisplayView,
    ChallengeSubmissionInputPipe,
    UnfinishedSubmissionDraftCtaCard,
    AcceptChallengeCtaCard,
    SuawHeading,
    SuawCtaCard,
    SuawInputGroup,
    SuawEmptyState
  },
  props: {
    submission: {
      type: Object,
      default: null
    },
    challengeId: {
      type: String,
      required: true
    },
    isChallengeOver: {
      type: Boolean,
      default: false
    },
    isChallengeAccepted: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    promptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      isChallengeAcceptedState: this.isChallengeAccepted
    };
  },
  computed: {
    isSubmitted() {
      return !!this.submission?.submitted_at;
    },
    isDrafted() {
      return !!this.submission?.drafted_at;
    },
    isEdited() {
      return !!this.submission?.last_edited_at;
    },
    lastSavedAt() {
      if (!this.submission) {
        return null; // Brand new posts shouldn't say last saved at
      }
      if (this.isEdited) {
        return format(new Date(this.submission.last_edited_at), "MMM d, yyyy h:mm a");
      }
      if (this.isSubmitted) {
        return format(new Date(this.submission.submitted_at), "MMM d, yyyy h:mm a"); // Submitted but not edited
      }
      if (this.isDrafted) {
        return format(new Date(this.submission.drafted_at), "MMM d, yyyy h:mm a"); // Only drafted
      }
      return format(new Date(), "MMM d, yyyy h:mm a"); // Fallback to current time
    }
  },
  methods: {
    handleEditClick() {
      this.isEditing = true;
    },
    handleCancelEdit() {
      this.isEditing = false;
    },
    handleSubmitSuccess() {
      this.isEditing = false;
      this.$emit("submit-success");
    },
    handleChallengeAccepted() {
      this.isChallengeAcceptedState = true;
    }
  }
};
</script>

<template>
  <div class="submission-yours">
    <SuawInputGroup direction="column" group-gap="triple">
      <SuawHeading level="3" content="Your Submission" />
      <div v-if="!isLoading">
        <SuawCtaCard
          v-if="!isDrafted && isChallengeOver"
          heading="This challenge has ended."
          text="This challenge ended before you could create a submission for this prompt. Check out the next challenge and click Accept to get email reminders of when prompts unlock. You can also read through past challenges and comment on other members' work."
          primary-button-text="Go Back To Challenges"
          @click="$router.push({ name: 'ChallengeDiscovery' })"
        />
        <UnfinishedSubmissionDraftCtaCard v-else-if="!isSubmitted && isChallengeOver" :draft="submission?.body_json" :last-saved-at="lastSavedAt" />
        <AcceptChallengeCtaCard v-else-if="!isChallengeAcceptedState" :challenge-id="challengeId" @challenge-accepted="handleChallengeAccepted" />
        <ChallengeSubmissionDisplayView v-else-if="isSubmitted && !isEditing" :submission="submission" @edit-click="handleEditClick" />
        <ChallengeSubmissionInputPipe
          v-else
          :prompt-id="promptId"
          :is-editing="isEditing"
          :is-submitted="isSubmitted"
          :last-saved-at="lastSavedAt"
          :initial-body-json="submission?.body_json"
          @submit-success="handleSubmitSuccess"
          @cancel-edit="handleCancelEdit"
        />
      </div>
      <SuawEmptyState v-else message="Loading your submission..." />
    </SuawInputGroup>
  </div>
</template>
