<script>
import { format, formatDistanceToNow } from "date-fns";
import { SuawParagraph, SuawDivider, SuawIcon, SuawSidepanel, SuawDropdown, SuawPostPipe, SuawLikeButtonPipe, SuawAvatarChipPipe } from "@/components";
import * as DiscussionApi from "@/features/discussions/api.js";

export default {
  name: "ChallengeSubmissionDisplayView",
  components: {
    SuawParagraph,
    SuawDivider,
    SuawIcon,
    SuawSidepanel,
    SuawPostPipe,
    SuawLikeButtonPipe,
    SuawAvatarChipPipe,
    SuawDropdown
  },
  props: {
    submission: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showSidepanel: false,
      isSubmissionRemoved: this.submission.deleted_at !== null,
      removedPostBodyJson: {
        type: "doc",
        content: [{ type: "paragraph", content: [{ type: "text", text: "This post has been hidden.", marks: [{ type: "italic" }] }] }]
      },
      postBodyJson: this.submission.body_json
    };
  },
  computed: {
    isAuthenticated() {
      return this.$auth && this.$auth.isAuthenticated;
    },
    authenticatedUserId() {
      return this.isAuthenticated ? this.$auth.user.id : null;
    },
    isAuthenticatedUserAdmin() {
      return this.isAuthenticated && this.$auth.user.role === "app_admin";
    },
    isSubmitted() {
      return !!this.submission.submitted_at;
    },
    submissionBody() {
      return this.isSubmissionRemoved ? this.removedPostBodyJson : this.postBodyJson;
    },
    isAuthorRemoved() {
      return !!this.submission.author?.deleted_at;
    },
    isEdited() {
      return !!this.submission.last_edited_at;
    },
    statusText() {
      return "Submitted:";
    },
    commentCount() {
      return this.submission?.replyCount || 0;
    },
    commentText() {
      return this.commentCount === 0 ? "No Comments" : `${this.commentCount} ${this.commentCount === 1 ? "Comment" : "Comments"}`;
    },
    formattedSubmissionDate() {
      return this.isSubmitted ? format(new Date(this.submission.submitted_at), "MMM d, yyyy h:mm a") : null;
    },
    formattedLastEditDate() {
      return this.isEdited ? format(new Date(this.submission.last_edited_at), "MMM d, yyyy h:mm a") : null;
    },
    displayDate() {
      return this.submission.submitted_at || this.submission.drafted_at;
    },
    authorId() {
      return this.submission.author.id;
    },
    postId() {
      return this.submission.id;
    },
    isOwnPost() {
      return this.authenticatedUserId === this.authorId;
    },
    dropdownItems() {
      if (!this.isAuthenticated) return [];
      return this.getDropdownItems();
    }
  },
  methods: {
    getRelativeTime(posted_at) {
      return formatDistanceToNow(new Date(posted_at), { addSuffix: true });
    },
    handleCommentClick() {
      this.showSidepanel = true;
    },
    getDropdownItems() {
      const options = [];
      if (this.isAuthenticatedUserAdmin) {
        if (this.isSubmissionRemoved) {
          options.push({ icon: "IconShow", text: "Unremove", postId: this.postId, authorId: this.authorId });
        } else {
          if (this.isOwnPost) {
            options.push(
              { icon: "IconEdit", text: "Edit", postId: this.postId, authorId: this.authorId },
              { icon: "IconHide", text: "Remove", postId: this.postId, authorId: this.authorId }
            );
          } else {
            options.push(
              { icon: "IconUserVoice", text: "Report", postId: this.postId, authorId: this.authorId },
              { icon: "IconHide", text: "Remove", postId: this.postId, authorId: this.authorId }
            );
          }
        }
      } else {
        if (this.isOwnPost) {
          options.push({ icon: "IconEdit", text: "Edit", postId: this.postId, authorId: this.authorId });
        } else {
          options.push({ icon: "IconUserVoice", text: "Report", postId: this.postId, authorId: this.authorId });
        }
      }

      return options;
    },
    async handleDropdownItemClick(option) {
      if (option.text === "Edit") {
        this.$emit("edit-click");
      }
      if (option.text === "Remove") {
        const result = await DiscussionApi.removePost(option.postId);
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        }
        this.isSubmissionRemoved = true;
      } else if (option.text === "Unremove") {
        const result = await DiscussionApi.unremovePost(option.postId);
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        }
        this.isSubmissionRemoved = false;
      } else if (option.text === "Report") {
        this.$root.$emit("universal-complaint", {
          context: "Post",
          postId: option.postId
        });
      }
    }
  }
};
</script>

<template>
  <div class="submission-card">
    <div class="submission-card__header">
      <SuawAvatarChipPipe :user-id="submission.author.id" />
      <div class="submission-card__header-section">
        <SuawParagraph class="submission-card__days-ago" weight="bolder" size="small" :text="getRelativeTime(displayDate)" />
        <SuawDropdown
          v-if="isAuthenticated"
          icon-dropdown="IconMoreHorizontal"
          dropdown-button-size="small"
          button-type="secondary-outline"
          button-class-name="suaw-dropdown__button--chip"
          :items="dropdownItems"
          @item-click="handleDropdownItemClick"
        />
      </div>
    </div>
    <SuawDivider />
    <SuawParagraph :text="submissionBody" />
    <SuawDivider />
    <div class="submission-card__footer">
      <div class="submission-card__dates">
        <div class="submission-card__date-group">
          <SuawParagraph weight="bolder" size="small" text="Submitted:" />
          <SuawParagraph class="submission-card__date" weight="bold" size="small" :text="formattedSubmissionDate" />
        </div>
        <div v-if="isEdited" class="submission-card__date-group">
          <SuawParagraph weight="bolder" size="small" text="Last edited at:" />
          <SuawParagraph class="submission-card__date" weight="bold" size="small" :text="formattedLastEditDate" />
        </div>
      </div>
      <div class="submission-card__actions">
        <div class="submission-card__action">
          <SuawLikeButtonPipe :post-id="submission.id" size="medium" />
        </div>
        <div class="submission-card__action" @click="handleCommentClick">
          <SuawIcon :style="{ marginTop: '2px' }" icon="IconComment" color="blue" size="medium" />
          <SuawParagraph class="submission-card__comments" size="small" :text="commentText" />
        </div>
      </div>
    </div>

    <SuawSidepanel v-if="showSidepanel" sidepanel-title="Back to Submission" @button-clicked="showSidepanel = false">
      <SuawPostPipe :post-id="submission.id" />
    </SuawSidepanel>
  </div>
</template>

<style lang="scss" scoped>
.submission-card {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  background: var(--sem-color-background-lightest);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-triple);
  box-shadow: var(--date-square-shadow);

  &__header {
    // height: 100%; //in order to align properly
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-section {
    height: 30px;
    display: flex;
    justify-content: center;
    gap: var(--sem-space-double);
  }

  &__days-ago {
    align-self: center;
  }

  &__footer {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @media(max-width: 650px) {
      grid-template-columns: 1fr;
    }
  }

  &__dates {
    display: flex;
    gap: var(--sem-space-double);
    @media(max-width: 850px) {
      flex-direction: column;
      gap: 4px;
    }
  }

  &__date-group {
    display: flex;
    align-items: center;
    gap: var(--sem-space-half);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: var(--sem-space-double);
    @media(max-width: 650px) {
      justify-self: flex-end;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    gap: var(--sem-space-half);
    cursor: pointer;
  }

  &__date {
    color: var(--sem-color-neutral-medium);
  }

  &__comments {
    color: var(--sem-color-info-medium);
    font-weight: 500;
    font-size: 16px;
  }
  &__likes {
    color: var(--sem-color-critical-medium);
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
