<script>
import { SuawMainContent, SuawPageHeaderActions, SuawDivider } from "@/components";
import ChallengePromptPipe from "../challenge/components/ChallengePromptPipe.vue";
import ChallengeSubmissionYoursPipe from "./components/ChallengeSubmissionYoursPipe.vue";
import ChallengeSubmissionListPipe from "./components/ChallengeSubmissionListPipe.vue";

export default {
  name: "ChallengePromptSubmission",
  components: {
    SuawMainContent,
    SuawPageHeaderActions,
    ChallengeSubmissionYoursPipe,
    ChallengeSubmissionListPipe,
    ChallengePromptPipe,
    SuawDivider
  },
  computed: {
    promptId() {
      return this.$route.params.promptId;
    }
  }
};
</script>

<template>
  <SuawMainContent size="large-small-gap" :show-gradient="true">
    <SuawPageHeaderActions
      back-text="Back to Challenge"
      :back-route-object="{ name: 'Challenge', params: { id: $route.params.challengeId, slug: $route.params.challengeSlug } }"
    />
    <SuawDivider />
    <ChallengePromptPipe :unlocked-is-clickable="false" :prompt-id="promptId" />
    <SuawDivider />
    <ChallengeSubmissionYoursPipe v-if="$auth.isAuthenticated" :prompt-id="promptId" />
    <SuawDivider />
    <ChallengeSubmissionListPipe :prompt-id="promptId" />
  </SuawMainContent>
</template>
