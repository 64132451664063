<script>
import ChallengeSubmissionYoursView from "./ChallengeSubmissionYoursView.vue";
import { GetYourSubmission } from "../challengePromptSubmissionOperations.gql";
import { SuawEmptyState, SuawHeading, SuawInputGroup } from "@/components";

export default {
  name: "ChallengeSubmissionYoursPipe",
  components: {
    ChallengeSubmissionYoursView,
    SuawEmptyState,
    SuawHeading,
    SuawInputGroup
  },
  props: {
    promptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    isChallengeOver() {
      const availableUntil = this.resultPrompt?.challenge?.available_until;
      if (!availableUntil) return false;
      return new Date(availableUntil) < new Date();
    },
    isChallengeAccepted() {
      return (this.resultPrompt?.challenge?.challenge_users?.length ?? 0) > 0;
    },
    challengeId() {
      return this.resultPrompt?.challenge?.id;
    },
    submission() {
      const promptUser = this.resultPrompt?.prompt_users[0];
      if (!promptUser) return null;

      // Calculate total reply count
      const replyCount = promptUser.post.posts_threads.reduce((total, thread) => {
        return total + (thread.threads_posts_aggregate?.aggregate?.count || 0);
      }, 0);

      return {
        ...promptUser.post,
        submitted_at: promptUser.submitted_at,
        replyCount
      };
    }
  },
  methods: {
    handleSubmitSuccess() {
      this.$apollo.queries.resultPrompt.refetch();
    }
  },
  apollo: {
    resultPrompt: {
      query: GetYourSubmission,
      variables() {
        return {
          promptId: this.promptId,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <ChallengeSubmissionYoursView
    v-if="!isLoading"
    :submission="submission"
    :is-loading="isLoading"
    :challenge-id="challengeId"
    :is-challenge-over="isChallengeOver"
    :is-challenge-accepted="isChallengeAccepted"
    :prompt-id="promptId"
    @submit-success="handleSubmitSuccess"
  />
  <SuawInputGroup v-else direction="column" group-gap="double">
    <SuawHeading level="3" content="Your Submission" />
    <SuawEmptyState message="Loading your submission..." />
  </SuawInputGroup>
</template>
