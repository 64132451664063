<script>
import { SuawParagraph, SuawHeading, SuawDivider, SuawIcon } from "@/components";
import { format } from "date-fns";

export default {
  name: "UnlockedChallengePromptView",
  components: {
    SuawParagraph,
    SuawHeading,
    SuawDivider,
    SuawIcon
  },
  props: {
    prompt: {
      type: Object,
      required: true
    },
    isClickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedDate() {
      if (this.isSubmitted) {
        return format(new Date(this.prompt.prompt_users[0].submitted_at), "MMM d, yyyy");
      }
      return format(new Date(this.prompt.unlocks_at), "MMM d, yyyy");
    },
    isSubmitted() {
      if (!this.$auth.isAuthenticated) return false;
      return this.prompt.prompt_users?.[0]?.submitted_at != null;
    },
    promptClass() {
      return this.isSubmitted ? "unlocked-challenge-prompt--submitted" : "";
    },
    statusText() {
      return this.isSubmitted ? "Submitted:" : "Unlocked:";
    },
    submissionCount() {
      return this.prompt.prompt_users_aggregate.aggregate.count;
    },
    submissionText() {
      return `${this.submissionCount} ${this.submissionCount === 1 ? "Submission" : "Submissions"}`;
    }
  }
};
</script>

<template>
  <router-link
    class="unlocked-challenge-prompt"
    :class="promptClass"
    :style="{
      '--cursor': isClickable ? 'pointer' : 'default',
      '--hover-transform': isClickable ? 'scale(0.99)' : 'none'
    }"
    :to="{ name: 'ChallengePromptSubmission', params: { challengeId: prompt.challenge.id, challengeSlug: prompt.challenge.slug, promptId: prompt.id, promptSlug: prompt.slug } }"
  >
    <div class="unlocked-challenge-prompt__title-row">
      <SuawHeading class="unlocked-challenge-prompt__title" level="3" :content="prompt.title" />
      <SuawIcon v-if="isSubmitted" icon="IconCheckboxChecked" color="gray" :pixel-size="32" />
      <SuawIcon v-else icon="IconCheckbox" color="gray" :pixel-size="32" />
    </div>
    <SuawParagraph :text="prompt.body_json" />
    <SuawDivider />
    <div class="unlocked-challenge-prompt__footer">
      <div class="unlocked-challenge-prompt__footer-section">
        <SuawParagraph weight="bolder" size="small" :text="statusText" />
        <SuawParagraph class="unlocked-challenge-prompt__release-date" weight="bold" size="small" :text="formattedDate" />
      </div>
      <div class="unlocked-challenge-prompt__footer-section">
        <SuawIcon :style="{ marginTop: '2px' }" icon="IconComment" color="carbon" size="medium" />
        <SuawParagraph class="unlocked-challenge-prompt__submissions" size="small" :text="submissionText" />
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.unlocked-challenge-prompt {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  text-decoration: none;
  background: var(--sem-color-background-lightest);
  border-radius: var(--sem-radius-md);
  padding: var(--sem-space-triple);
  transition: transform 0.25s ease;
  box-shadow: var(--date-square-shadow);
  cursor: var(--cursor);
  &:hover {
    transform: var(--hover-transform);
  }

  &--submitted {
    background: var(--sem-color-success-light);
  }

  &__title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
  }

  &__title {
    font-weight: 700;
  }

  &__footer {
    height: 24px; //in order to align properly
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footer-section {
    display: flex;
    justify-content: center;
    gap: var(--sem-space-half);
  }

  &__release-date {
    color: var(--sem-color-neutral-medium);
  }

  &__submissions {
    // color: var(--sem-color-info-medium);
    color: var(--sem-color-text-medium);
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
