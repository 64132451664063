import { DraftPromptResponse, SubmitPromptResponse } from "./challengePromptSubmissionOperations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
import { call } from "@/utils/api/base.js";

export async function draftPromptResponse(promptId, bodyJson) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Prompt does not exist."],
    [BE.NOT_BELONG, "User has not accepted the challenge."],
    [BE.LOCKED, "Prompt is still locked."],
    [BE.ALREADY_EXISTS, "User has already responded to this prompt."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Failed to draft prompt response. Please try again."],
    [BE.UPDATE_FAILED, "Failed to draft prompt response. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DraftPromptResponse,
        variables: {
          promptId,
          bodyJson
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function submitPromptResponse(promptId, bodyJson) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Prompt does not exist."],
    [BE.ALREADY_EXISTS, "User has already responded to this prompt."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Failed to submit prompt response. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);

  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: SubmitPromptResponse,
        variables: {
          promptId,
          bodyJson
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
