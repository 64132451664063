<script>
import { SuawEditorBox } from "@/components";

export default {
  name: "ChallengeSubmissionInputView",
  components: {
    SuawEditorBox
  },
  props: {
    initialBodyJson: {
      type: Object,
      default: null
    },
    isSubmitted: {
      type: Boolean
    },
    lastSavedAt: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      draftTimer: null,
      editorValue: this.initialBodyJson || { type: "doc", content: [{ type: "paragraph" }] }
    };
  },
  watch: {
    editorValue: {
      handler() {
        this.debouncedDraft();
      },
      deep: true
    }
  },
  methods: {
    handleSubmit(submission) {
      this.$emit("submit", submission);
    },
    debouncedDraft() {
      clearTimeout(this.draftTimer);
      this.draftTimer = setTimeout(() => {
        this.$emit("draft", this.editorValue);
      }, 2000);
    }
  }
};
</script>

<template>
  <SuawEditorBox
    v-model="editorValue"
    button-text="Submit"
    :secondary-button-text="isSubmitted ? 'Cancel Edit' : null"
    input-message="Create a submission here."
    :use-formatting="true"
    :use-tip-tap="true"
    :status-text="lastSavedAt ? 'Last saved at:' : ''"
    :status-date="lastSavedAt"
    @secondary-click="$emit('cancel-edit')"
    @submit="handleSubmit"
  />
</template>
